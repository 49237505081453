
<template>
  <div id="page-user-rdit">
    <div class="vx-row">
      <div class="vx-col lg:w-2/3 w-full">
        <vs-alert color="danger" title="Record Not Found" :active.sync="user_not_found">
          <span>Record not found.  Sorry something went wrong!</span>
          <span>
          </span>
        </vs-alert>
        <vx-card v-if="!isLoading" >
          <div slot="no-body" class="tabs-container px-6 pt-6">
            <vs-tabs class="tab-action-btn-fill-conatiner">
              <vs-tab :label="!isSmallerScreen ? 'Account' : 'Acc'" icon-pack="feather" icon="icon-user">
                <div class="tab-text">
                  <user-edit-tab-account class="mt-4" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'Settings' : 'Set'" icon-pack="feather" icon="icon-settings">
                <div class="tab-text">
                  <user-edit-tab-information class="mt-4" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'Password' : 'Pass'" icon-pack="feather" icon="icon-lock">
                <div class="tab-text">
                  <user-edit-tab-password class="mt-4" />
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </vx-card>
        </div>
      </div>
  </div>
</template>

<script>
import UserEditTabAccount from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
  },
  data() {
    return {
      isLoading: true,
      user_not_found: false,
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  created() {
    if(this.$store.state.isActiveUserUpdated){
      this.isLoading = false;
    }else{
      this.$store.dispatch("fetchActiveUser")
      .then((response) => {
        if(response.data.success){
          this.$store.commit("ACTIVE_USER_INFO_UPDATED",true)
          this.isLoading = false
        }else{
          this.user_not_found = true
      }
      })
    }
    this.$store.dispatch('fetchCountries')
    this.$store.dispatch('fetchLanguages')
    this.$store.dispatch('fetchSkills')
  }

}

</script>
